<template>
    <v-col :cols="config.cols">
        <v-btn class="mx-2" small color="primary" @click="download" :disabled="isDisabled">
            <v-icon left>mdi-download</v-icon>
            {{ config.label }}
        </v-btn>
    </v-col>
</template>

<script>

import moment from 'moment';

export default {
    name: 'DownloadButton',
    props: ['config', 'prefix'],
    methods: {
        download() {
            this.config.click()
            if (this.config.click) {
                this.config.click().then(res => {
                    let a = document.createElement('a');
                    a.style = "display: none";
                    let blob = new Blob([new Uint8Array(this.prefix), res.data], {encoding:"UTF-8", type: "application/octet-stream"});
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    if (this.config.fileName) {
                        a.download = moment(new Date()).format('YYYYMMDD-HHmm') + '-' + this.config.fileName;
                    } else {
                        a.download = res.headers['x-filename'];
                    }
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
            }
        }
    },
    computed: {
        isDisabled() {
            return this.config.isDisabled && this.config.isDisabled();
        }
    }
}
</script>
